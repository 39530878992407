<template>
  <div class="login">
    <b-container>
      <b-row>
        <b-col></b-col>
        <b-col xl="3" lg="4" md="5" sm="6" cols>
          <h2 class="text-center mb-4">Anmelden</h2>
          <b-button @click="login" variant="warning" block>
            <b-icon icon="box-arrow-right" class="mr-1" /> Mit Microsoft365 anmelden
          </b-button>
          <!--<form @submit.prevent="login">
            <b-form-group
                id="email-group"
                label="E-Mail"
                label-for="email"
            >
              <b-form-input
                  id="email"
                  v-model="email"
                  type="email"
                  placeholder="max.muster@cg-esslingen.de"
                  required
              ></b-form-input>
            </b-form-group>
            <b-form-group
                id="password-group"
                label="Passwort"
                label-for="password"
            >
              <b-form-input
                  id="password"
                  v-model="password"
                  type="password"
                  placeholder="******"
                  required
              ></b-form-input>
            </b-form-group>
            <b-form-invalid-feedback :state="!error">Fehler</b-form-invalid-feedback>
            <b-button type="submit" variant="primary" block>Anmelden</b-button>
          </form>-->
        </b-col>
        <b-col></b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { sha256, decrypt } from "@/tools/crypto";
import api from "@/api";

export default {
  name: 'Login',
  components: {
  },
  data() {
    return {
      email: '',
      password: '',
      error: false,
    }
  },
  methods: {
    ucFirst(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    getName(id) {
      const name = id.split('.');
      return name.map(this.ucFirst).join(' ');
    },
    login (inBackground = false) {
      api.login().then(response => response.data).then(data => {
        if (data.success) {
          //confirm the session
          api.status().then(response => response.data).then(data => {
            if (data.authenticated) {
              sessionStorage.setItem('isAuthenticated', 'yes');
              sessionStorage.setItem('name', this.getName(data.user.id));

              window.location.href = '/'; // to make use of session-/localStorage
            }
          });
        } else {
          if (!inBackground) window.location.href = data.redirect;
        }
      }).catch(error => {
        // TODO error handling
        console.log(error);

        if (!inBackground) this.error = true;
      });

      // api.login(this.email, this.password).then(response => response.data).then(data => {
      //   if (data.success) {
      //     localStorage.setItem('email', this.email);
      //     sessionStorage.setItem('name', this.getName(this.email));
      //
      //     // save userKey for this session
      //     //sessionStorage.setItem('key', this.buildUserKey(data.token));
      //     sessionStorage.setItem('key', 'x');
      //
      //     window.location.href = '/'; // to make use of session-/localStorage
      //   } else {
      //     // TODO error handling
      //     this.error = true;
      //   }
      // }).catch(error => {
      //   // TODO error handling
      //   console.log(error);
      //
      //   this.error = true;
      // });
    },
    buildUserKey (token) {
      return [
        //cookieValue('connected_at'),// get cookie time
        '000', // TEMP while local dev deploy
        sha256(this.password), // hash password
        decrypt(token, this.password), // decrypt token
      ].join('.');
    }
  },
  mounted() {
    this.login(true);
  }
}
</script>

<style lang="scss" scoped>
.login {
  padding-top: 3rem;
}
</style>
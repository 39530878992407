<template>
  <div class="home">
    <b-container v-if="loading"><h3 class="text-center mt-4">Bitte warten...</h3></b-container>
    <b-container v-else class="bv-example-row">
      <b-row>
        <b-col lg="4" md="5" sm cols>
          <b-card no-body>
            <template #header>
              <div class="d-flex justify-content-between align-items-center">
                <h5 class="m-0">Deine Gruppen</h5>
                <b-button variant="light" size="sm" style="opacity:0">
                  _
                </b-button>
              </div>
            </template>
            <b-list-group v-if="groups.length" flush>
              <b-list-group-item
                  v-for="g in groups"
                  :key="g.id"
                  button
                  @click="group = g">
                <span class="h5 mr-3"><b-icon :icon="g.icon" /></span>
                <strong>{{ g.name }}</strong>
              </b-list-group-item>
            </b-list-group>
            <b-card-text class="p-3 text-center h5" v-else>
              Keine Gruppen :(
            </b-card-text>
          </b-card>
          <div class="text-center mb-3">
            <small class="text-muted">Angemeldet als {{ userName }}</small>
          </div>
        </b-col>
        <b-col lg="8" md="7" sm cols>
          <b-card no-body>
            <template #header>
              <div class="d-flex justify-content-between align-items-center">
                <h5 class="m-0">{{ group ? group.name : 'Einträge' }}</h5>
                <b-button variant="outline-success" size="sm" @click="edit(null)" v-if="group">
                  <b-icon icon="plus" />
                  <span class="d-none d-lg-inline">Eintrag hinzufügen</span>
                  <span class="d-lg-none">Hinzufügen</span>
                </b-button>
              </div>
            </template>
            <b-list-group v-if="groupEntries.length" flush>
              <b-list-group-item
                  v-for="(entry, i) in groupEntries"
                  :key="'entry-' + i"
                  class="d-flex align-items-center">
                <ServiceIcon :service="entry.title" class="m-0 mr-4" />
                <div>
                  <h5 class="m-0">{{entry.title}}</h5>
                  <small class="text-muted">
                    {{entry.username}}
                    <template v-if="entry.url">
                      <span>&nbsp;&middot;&nbsp;</span>
                      <b-link class="text-muted" :href="entry.url" target="_blank">
                        {{ getDomain(entry.url) }}&nbsp;
                        <b-icon icon="box-arrow-up-right" class="ml-1" />
                      </b-link>
                    </template>
                  </small>
                </div>
                <b-button variant="outline-primary" size="sm" class="ml-auto" @click="edit(entry)"><b-icon icon="pencil-square" /></b-button>
                <b-dropdown :variant="copied === i ? 'outline-success' : 'outline-secondary'" size="sm" class="ml-1">
                  <template #button-content>
                    <b-icon :icon="copied === i ? 'clipboard-check' : 'clipboard'" />
                  </template>
                  <b-dropdown-item @click="copyRaw(entry.username, i)">Benutzername kopieren</b-dropdown-item>
                  <b-dropdown-item @click="copyEncrypted(entry.password, i)">Passwort kopieren</b-dropdown-item>
                </b-dropdown>
              </b-list-group-item>
            </b-list-group>
            <b-card-text class="p-3 text-center h4" v-else>
              Keine Einträge :(
            </b-card-text>
          </b-card>
        </b-col>
      </b-row>
      <EntryModal :data="modalEntry" :group="group.id" v-if="group"
        @closing="modalClosing"
      />
    </b-container>
  </div>
</template>

<script>
import api from "@/api";
import {decrypt} from "@/tools/crypto";
import {copy} from "@/tools/copy";
import ServiceIcon from "@/components/ServiceIcon";
import EntryModal from "@/components/EntryModal";

export default {
  name: 'Home',
  components: {
    EntryModal,
    ServiceIcon,
  },
  data() {
    return {
      loading: true,
      entries: [],
      groups: [],
      group: '',
      copied: '',
      modalEntry: null,
    }
  },
  computed: {
    userName() {
      return sessionStorage.getItem('name');
    },
    groupEntries() {
      if (!this.groups.length || !this.entries.length) return false;

      return this.entries.filter(e => e.groups.includes(this.group.id))
    }
  },
  methods: {
    edit(entry) {
      this.modalEntry = entry;
      this.$bvModal.show('entry-modal');
    },
    getDomain(link) {
      link = link.replace(/http(s)?:\/\//g, '');
      link = link.split('/');
      return link[0];
    },
    copyEncrypted(encrypted, id) {
      this.copyRaw(decrypt(encrypted, 'SHARED_KEY_z78t6fzvghu3rq9iufwwjf09u3342h98aef912e'), id);
    },
    copyRaw(string, id) {
      copy(string, function(){
        this.copied = id;
        setTimeout(() => this.copied = '', 1500);
      }.bind(this));
    },
    getGroups() {
      api.groups().then(response => response.data).then(data => {
        this.groups = data.groups;
        this.group = data.groups[0];
      });
    },
    getEntries() {
      api.entries().then(response => response.data).then(data => {
        this.entries = data.entries;
      });
    },
    modalClosing(reload) {
      if (reload) this.getEntries();
    },
    ucFirst(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    getName(id) {
      const name = id.split('.');
      return name.map(this.ucFirst).join(' ');
    },
  },
  mounted() {
    api.status().then(response => response.data).then(data => {
      if (data.authenticated) {
        sessionStorage.setItem('isAuthenticated', 'yes');
        sessionStorage.setItem('name', this.getName(data.user.id));

        this.getGroups()
        this.getEntries();

        this.loading = false;
      } else {
        window.location.href = '/login';
      }
    }).catch(error => {
      // TODO error handling
      console.log(error);

      this.error = true;
    });
  }
}
</script>

<style lang="scss" scoped>
.home {
  padding-top: 1rem;
}
</style>

import axios from "axios";

const axs = axios.create({
  baseURL: 'https://api.passwords.cg-esslingen.de/v2',
  withCredentials: true
});

const api = {
  login: () => axs.post('/login'),
  status: () => axs.post('/status'),
  logout: () => axs.get('/logout'),
  groups: () => axs.get('/groups'),
  entries: () => axs.get('/entries'),
  saveEntry: (id, data) => axs.post('/entries/' + id, data),
}

export default api;
<template>
  <div class="h2 mb-0">
    <b-icon :icon="icon.name" :style="style"></b-icon>
  </div>
</template>

<script>
export default {
  name: 'ServiceIcon',
  props: ['service'],
  data() {
    return {
      services: [
        {
          name: 'Google',
          icon: { name: 'google', color: '#4285F4' },
        },
        {
          name: 'Facebook',
          icon: { name: 'facebook', color: '#4267B2' },
        },
        {
          name: 'YouTube',
          icon: { name: 'youtube', color: '#FF0000' },
        },
        {
          name: 'Instagram',
          icon: { name: 'instagram', color: '#405DE6' },
        },
        {
          name: 'Twitter',
          icon: { name: 'twitter', color: '#1DA1F2' },
        },
        {
          name: 'Twitch',
          icon: { name: 'twitch', color: '#6441a5' },
        },
        {
          name: 'Website',
          icon: { name: 'globe', color: '#090000' },
        },
        {
          name: 'Gitlab',
          icon: { name: 'terminal', color: '#E2432A' },
        },
        {
          name: 'Trello',
          icon: { name: 'kanban-fill', color: '#0079BF' },
        },
        {
          name: 'Hetzner',
          icon: { name: 'hdd-rack', color: '#D50B2D' },
        },
        {
          name: 'LinkedIn',
          icon: { name: 'linkedin', color: '#2867B2' },
        },
        {
          name: 'Sipgate',
          icon: { name: 'telephone', color: '#ff8b00' },
        },
        {
          name: 'Sipgate',
          icon: { name: 'telephone', color: '#ff8b00' },
        },
      ],
    }
  },
  computed: {
    style() {
      return 'color: ' + this.icon.color + ';'
    },
    icon() {
      let service = this.services.find(service => this.service.toLowerCase().indexOf(service.name.toLowerCase()) > -1);
      if (!service) return { name: 'key', color: 'inherit' };
      return service.icon;
    }
  }
}
</script>
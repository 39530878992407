<template>
  <div class="entry-modal">
    <b-modal id="entry-modal" size="lg" :title="title" @hide="$emit('closing', revisions > 0)">
      <template #modal-footer="{ cancel }">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button variant="outline-secondary" @click="cancel()" v-if="isUnchanged || isNew">
          Schließen
        </b-button>
        <b-button variant="outline-danger" @click="abort" v-if="!isUnchanged && !isNew">
          Zurücksetzen
        </b-button>
        <b-button variant="success" @click="save" v-if="!isUnchanged || isNew">
          Speichern
        </b-button>
      </template>

      <b-form-group
          id="fieldset-title"
          label-cols-sm="4"
          label-cols-lg="3"
          content-cols-sm
          content-cols-lg="7"
          label="Service"
          label-for="input-title"
      >
        <b-form-input type="text" id="input-title" :required="true" v-model="entry.title"></b-form-input>
      </b-form-group>
      <b-form-group
          id="fieldset-username"
          label-cols-sm="4"
          label-cols-lg="3"
          content-cols-sm
          content-cols-lg="7"
          label="Benutzername"
          label-for="input-username"
      >
        <b-input-group>
          <b-form-input type="text" id="input-username" :required="true" v-model="entry.username"></b-form-input>
          <b-input-group-append>
            <b-button
                :variant="copied === 'username' ? 'outline-success' : 'outline-dark'"
                @click="copy('username')">
              <b-icon :icon="copied === 'username' ? 'clipboard-check' : 'clipboard'"/>
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-form-group>
      <b-form-group
          id="fieldset-password"
          label-cols-sm="4"
          label-cols-lg="3"
          content-cols-sm
          content-cols-lg="7"
          label="Passwort"
          label-for="input-password"
      >
        <b-input-group>
          <b-form-input :type="showPassword ? 'text' : 'password'" id="input-password" :readonly="!showPassword && !isNew" :required="true" v-model="entry.password"></b-form-input>
          <b-input-group-append>
            <b-button
                variant="outline-dark"
                :pressed.sync="showPassword">
              <b-icon :icon="showPassword ? 'eye-slash' : 'eye'" />
            </b-button>
            <b-button
                :variant="copied === 'password' ? 'outline-success' : 'outline-dark'"
                @click="copy('password')">
              <b-icon :icon="copied === 'password' ? 'clipboard-check' : 'clipboard'"/>
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-form-group>
      <b-form-group
          id="fieldset-url"
          label-cols-sm="4"
          label-cols-lg="3"
          content-cols-sm
          content-cols-lg="7"
          label="URL"
          label-for="input-url"
      >
        <b-input-group>
          <b-form-input type="text" id="input-url" v-model="entry.url"></b-form-input>
          <b-input-group-append>
            <b-button variant="outline-dark" :href="entry.url" target="_blank"><b-icon icon="box-arrow-up-right"/></b-button>
          </b-input-group-append>
        </b-input-group>
      </b-form-group>
      <b-form-group
          id="fieldset-notes"
          label-cols-sm="4"
          label-cols-lg="3"
          content-cols-sm
          content-cols-lg="7"
          label="Notizen"
          label-for="input-notes"
      >
        <b-form-textarea
            id="input-notes"
            v-model="entry.notes"
            placeholder="Keine Notizen"
            rows="3"
            max-rows="6"
        ></b-form-textarea>
      </b-form-group>

      <small class="text-muted" v-if="data">Letzte Änderung: {{ entry.edited_by }}, {{ entry.updated_at ? entry.updated_at : entry.created_at }}</small>
    </b-modal>
  </div>
</template>

<script>
import {decrypt,encrypt} from "@/tools/crypto";
import {copy} from "@/tools/copy";
import api from "@/api";

export default {
  name: 'EntryModal',
  props: ['data', 'group'],
  watch: {
    data: function(newVal) {
        this.resetModal(newVal);
    }
  },
  data() {
    return {
      showPassword: false,
      copied: '',
      revisions: 0,
      entry: {},
      originalEntry: {},
      entryTemplate: {
        id: 'new',
        title: '',
        password: '',
        username: '',
        notes: '',
        url: '',
        edited_by: '',
        updated_at: '',
        created_at: '',
      },
    }
  },
  computed: {
    title() {
      return this.isNew ? 'Eintrag hinzufügen' : 'Eintrag bearbeiten'
    },
    isNew() {
      return this.entry.id === 'new'
    },
    changed() {
      return Object.keys(this.entry).filter(key => this.entry[key] !== this.originalEntry[key]);
    },
    isUnchanged() {
      //if (this.isNew) return false;
      /*return [
        'title',
        //'password',
        'username',
        'notes',
        'url',
        //'edited_by',
        //'updated_at',
        //'created_at',
      ].every(key => this.data[key] === this.entry[key]);*/

      //return Object.keys(this.entry).every(key => this.entry[key] === this.originalEntry[key]);

      return this.changed && this.changed.length === 0
    }
  },
  methods: {
    save() {
      let save = {
        // title: this.entry.title,
        // username: this.entry.username,
        // password: encrypt(this.entry.password, sessionStorage.getItem('key')),
        // notes: this.entry.notes,
        // url: this.entry.url,
        // groups: this.group,
      };

      this.entry.password = encrypt(this.entry.password, 'SHARED_KEY_z78t6fzvghu3rq9iufwwjf09u3342h98aef912e');

      if (this.isNew) {
        save = {
          title: this.entry.title,
          username: this.entry.username,
          password: this.entry.password,
          notes: this.entry.notes,
          url: this.entry.url,
          groups: this.group,
        };
      } else {
        this.changed.forEach(key => {
          if (key === 'password') {
            //save[key] = encrypt(this.entry[key], sessionStorage.getItem('key'));
            //return;
          }

          save[key] = this.entry[key];
        });
      }

      api.saveEntry(this.entry.id, save).then(response => response.data).then(data => {
        if (data.success) {
          this.$bvToast.toast(this.isNew ? 'Eintrag erstellt' : 'Eintrag gespeichert', {
            toaster: 'b-toaster-top-center',
            noCloseButton: true,
            variant: 'success',
            solid: true
          });

          this.entry.id = data.entry.id;
          let temp = this.copyObject(this.entry);
          this.resetModal(temp);

          this.revisions++;
        } else {
          this.$bvToast.toast('Serverfehler!', {
            toaster: 'b-toaster-top-center',
            noCloseButton: true,
            variant: 'danger',
            solid: true
          });

          this.entry.password = decrypt(this.entry.password, 'SHARED_KEY_z78t6fzvghu3rq9iufwwjf09u3342h98aef912e');
        }
      });
    },
    abort() {
      this.resetModal(this.originalEntry);
    },
    copy(field) {
      copy(this.entry[field], function(){
        this.copied = field;
        setTimeout(() => this.copied = '', 1500);
      }.bind(this));
    },
    resetModal(data) {
      // reset
      this.entry = this.copyObject(this.entryTemplate);
      this.originalEntry = this.copyObject(this.entryTemplate);
      if (data === null) return;

      // populate
      Object.keys(data).forEach(key => {
        this.entry[key] = data[key];
      });
      this.entry.password = decrypt(this.entry.password, 'SHARED_KEY_z78t6fzvghu3rq9iufwwjf09u3342h98aef912e');

      this.originalEntry = this.copyObject(this.entry);
      this.showPassword = false;
    },
    copyObject: obj => Object.assign({}, obj),
  },
  mounted() {
    this.resetModal(null);
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>

import CryptoJS from 'crypto-js';

export const sha256 = value => CryptoJS.SHA256(value).toString(CryptoJS.enc.Hex);
export const encrypt = (value, key) => {
  const iv = CryptoJS.lib.WordArray.random(16);
  key = CryptoJS.enc.Hex.parse(sha256(key));

  const encrypted = CryptoJS.AES.encrypt(value, key, {
    iv
  });

  return encrypted.ciphertext.toString(CryptoJS.enc.Hex) + '.' + encrypted.iv.toString(CryptoJS.enc.Hex);
}
export const decrypt = (value, key) => {
  value = value.split('.');
  const ciphertext = CryptoJS.enc.Hex.parse(value[0]);
  const iv = CryptoJS.enc.Hex.parse(value[1]);
  key = CryptoJS.enc.Hex.parse(sha256(key));

  const params = CryptoJS.lib.CipherParams.create({
    ciphertext: ciphertext
  });
  const decrypted = CryptoJS.AES.decrypt(params, key, {
    iv
  });

  return decrypted.toString(CryptoJS.enc.Utf8);
}
/*

function encrypt($value, $key, $algo = 'aes-256-cbc') {
  $ivLength = openssl_cipher_iv_length($algo);
  $iv = openssl_random_pseudo_bytes($ivLength);
  $key = hash('sha256', $key);

  $ciphertext = openssl_encrypt($value, $algo, $key, OPENSSL_RAW_DATA, $iv);

  return bin2hex($ciphertext).'.'.bin2hex($iv);
}
function decrypt($value, $key, $algo = 'aes-256-cbc') {
  //return $value; // TODO decrypt with $key

  $value = explode('.', $value);
  $ciphertext = hex2bin($value[0]);
  $iv = hex2bin($value[1]);
  $key = hash('sha256', $key);

  //$iv = hex2bin('69a64fd6f89032f849a7d9157debc6e9');

  $raw = openssl_decrypt($ciphertext, $algo, $key, OPENSSL_RAW_DATA, $iv);
  return $raw;
}*/

<template>
  <div id="app">
    <b-navbar toggleable="sm" type="light" variant="light">
      <b-container>
        <b-navbar-brand>CGE Passwords</b-navbar-brand>

        <b-collapse id="nav-text-collapse" is-nav>
          <b-navbar-nav class="ml-auto" v-if="isAuthenticated">
            <!--<b-nav-item @click="logoutAndGo('/locked')">
              <b-icon icon="lock" class="mr-1" />
              Sperren
            </b-nav-item>-->
            <b-nav-item @click="logoutAndGo('/login')">
              <b-icon icon="box-arrow-right" class="mr-1" />
              Abmelden
            </b-nav-item>
          </b-navbar-nav>

          <b-navbar-nav class="ml-auto" v-else>
            <b-nav-item href="/login">
              <b-icon icon="box-arrow-right" class="mr-1" />
              Anmelden
            </b-nav-item>
          </b-navbar-nav>
        </b-collapse>

        <b-navbar-toggle target="nav-text-collapse"></b-navbar-toggle>
      </b-container>
    </b-navbar>

    <router-view/>
  </div>
</template>

<script>
import api from "@/api";
import {isAuthenticated} from '@/tools/authy';

export default {
  name: 'App',
  computed: {
    isAuthenticated() {
      return isAuthenticated();
    }
  },
  methods: {
    logoutAndGo(path) {
      api.logout().then(response => response.data).then(data => {
        console.log(data);

        sessionStorage.removeItem('isAuthenticated');
        window.location.href = path; // to make use of session-/localStorage
      });
    }
  }
}
</script>

<style lang="scss">
body {
  background: #e5e5e5 !important;
}
</style>
